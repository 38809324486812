<template>
  <label
    :class="[
      disabled
        ? `v-arora-input v-arora-input--${appConfig.VueSettingsPreRun.InputLayout} disabled`
        : `v-arora-input v-arora-input--${appConfig.VueSettingsPreRun.InputLayout}`
    ]"
    :inert="disabled"
  >
    <input
      :id="uid"
      :aria-label="label.toString()"
      :data-test-id="dataTestId"
      :required="required"
      placeholder="&nbsp;"
      type="date"
      v-model="input"
      @keyup.enter="emit('keyup.enter')"
    />
    <span
      :class="`v-arora-input--${appConfig.VueSettingsPreRun.InputLayout}__label`"
    >
      <span
        class="v-pointer-events-none"
        v-html="label"
      />
      <span
        v-if="required"
        class="v-required"
      />
    </span>
    <span
      :class="`v-arora-input--${appConfig.VueSettingsPreRun.InputLayout}__right-end`"
    >
      <slot name="rightEnd" />
    </span>
  </label>
</template>

<script setup lang="ts">
import { useCommon, type VElement } from '@arora/common'

const { required = false, text } = defineProps<
  VElement & {
    text: number | string | null | undefined
  }
>()
const emit = defineEmits(['update:text', 'keyup.enter'])

const appConfig = useAppConfig()

const { stringIsNullOrWhitespace } = useCommon()

const uid = useId()

const input = computed<number | string | null | undefined>({
  get() {
    return stringIsNullOrWhitespace(text?.toString()) ? null : text
  },
  set(value) {
    emit('update:text', value)
  }
})
</script>
